import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthProvider } from "./util/auth";
import { AppRouter } from "./components/AppRouter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#303030",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

export default function App() {  
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
  );
}
