import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../util/auth";

export const RequireAuth = ({ children }) => {
    const { user } = useContext(AuthContext);
    const location = useLocation(); 
    
    if (!user) {
      return <Navigate to="/sign-in" replace state={{from: location.pathname}} />;
    }
    return <>{children}</>;
  };
  