import { AppBar } from "@mui/material";
import { Box } from "@mui/material";
import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { Drawer } from "@mui/material";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { auth, AuthContext } from "../util/auth";
import { signOut } from "firebase/auth";
import { useContext } from "react";

export const NavBar = () => {
  const { user } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              data-test="menu-button"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MALOOBA
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Drawer
        data-test="menu"
        anchor="left"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <Box sx={{ width: 240 }} role="presentation">
          <List>
            <Box sx={{ height: 64, p: 0 }}></Box>


            {user ? (
              <ListItem
                data-test="menu-dashboard"
                button
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            ) : null}

            {user ? (
              <ListItem
                data-test="menu-sign-out"
                button
                onClick={() => {
                  signOut(auth);
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            ) : (
              <ListItem
                data-test="menu-sign-in"
                button
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Sign In" />
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
