import { Layout } from "./Layout";
import { Grid } from "@mui/material";

export const PageNotFoundPage = () => {
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item sx={{ width: 300 }}>
          <h1>Page not found</h1>
        </Grid>
      </Grid>
    </Layout>
  );
};
