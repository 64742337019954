import { Layout } from "./Layout";
import { Grid } from "@mui/material";

export const PasswordResetSentPage = () => {
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item sx={{ width: 400 }}>
          <h1 style={{ textAlign: "center" }}>Password Reset Sent</h1>
        </Grid>
        
      </Grid>
    </Layout>
  );
};
