import { useContext } from "react";
import { Layout } from "./Layout";
import { AuthContext } from "../util/auth";

export const DashboardPage = () => {
  const {user} = useContext(AuthContext);

  return (
    <Layout>
      <h1>Dashboard</h1>
      <pre>{JSON.stringify(user, null, 2)}</pre>
    </Layout>
  );
};
