import { useEffect, useContext } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { auth, AuthContext } from "../util/auth";
import { Layout } from "./Layout";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "@mui/material";
import { Typography } from "@mui/material";

export function SignInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      return navigate(location?.state?.from ?? "/dashboard");
    }
  }, [user, location, navigate]);

  return (
    <Layout>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item sx={{ width: 300 }}>
          <h1 style={{ textAlign: "center" }}>Hi! Sign in</h1>
          <TextField
            data-test="sign-in-email"
            value={email}
            fullWidth
            label="Email"
            variant="outlined"
            required
            onChange={(text) => setEmail(text.target.value)}
          />
        </Grid>

        <Grid item sx={{ width: 300 }}>
          <TextField
            data-test="sign-in-password"
            value={password}
            label="Password"
            variant="outlined"
            required
            fullWidth
            onChange={(text) => setPassword(text.target.value)}
            type="password"
          />
        </Grid>

        <Grid item sx={{ width: 300 }}>
          <LoadingButton
            data-test="sign-in-sign-in"
            loading={submitting}
            variant="contained"
            fullWidth
            disabled={!(email && password)}
            onClick={() => {
              setSubmitting(true);
              signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                  navigate(location?.state?.from ?? "/dashboard");
                })
                .catch(() => {
                  setSnackbarOpen(true);
                  setSubmitting(false);
                });
            }}
          >
            Sign In
          </LoadingButton>
        </Grid>

        <Grid item sx={{ width: 300 }}>
          <Typography variant="button">
            <Link data-test="sign-in-password-reset" href="/password-reset" underline="none">
              password reset
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        data-test="sign-in-snackbar"
        open={snackbarOpen}
        message="Sign in failed."
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbarOpen(false)}
          >
            OK
          </Button>
        }
      />
    </Layout>
  );
}
