import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "./Layout";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Snackbar } from "@mui/material";
import { Button } from "@mui/material";
import { auth } from "../util/auth";
import { confirmPasswordReset } from "firebase/auth";

export const PasswordUpdatePage = () => {
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const oobCode = new URLSearchParams(location.search).get("oobCode");

  return (
    <Layout>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item sx={{ width: 300 }}>
          <h1 style={{ textAlign: "center" }}>Password Update</h1>
          <TextField
            value={password}
            label="Password"
            variant="outlined"
            required
            fullWidth
            onChange={(text) => setPassword(text.target.value)}
            type="password"
          />
        </Grid>

        <Grid item sx={{ width: 300 }}>
          <LoadingButton
            loading={submitting}
            variant="contained"
            fullWidth
            disabled={!password}
            onClick={() => {
              setSubmitting(true);
              return confirmPasswordReset(auth, oobCode, password)
                .then(() => {
                  setSubmitting(false);
                  navigate("/dashboard");
                })
                .catch(() => {
                  setSnackbarOpen(true);
                  setSubmitting(false);
                });
            }}
          >
            Update Password
          </LoadingButton>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        message="Password update failed."
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbarOpen(false)}
          >
            OK
          </Button>
        }
      />
    </Layout>
  );
};
