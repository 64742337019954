import { useState } from "react";
import { Layout } from "./Layout";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Snackbar } from "@mui/material";
import { Button } from "@mui/material";
import { auth } from "../util/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const PasswordResetPage = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Layout>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item sx={{ width: 300 }}>
          <h1 style={{ textAlign: "center" }}>Password Reset</h1>
          <TextField
            data-test="email"
            value={email}
            fullWidth
            label="Email"
            variant="outlined"
            required
            onChange={(text) => setEmail(text.target.value)}
          />
        </Grid>

        <Grid item sx={{ width: 300 }}>
          <LoadingButton
            data-test="password-reset"
            loading={submitting}
            variant="contained"
            fullWidth
            disabled={!email}
            onClick={() => {
              setSubmitting(true);
              return sendPasswordResetEmail(auth, email)
                .then(() => {
                  navigate("/password-reset-sent");
                })
                .catch((error) => {
                  setSnackbarOpen(true);
                  setSubmitting(false);
                });
            }}
          >
            Reset Password
          </LoadingButton>
        </Grid>

      </Grid>
      <Snackbar
        data-test="snackbar"
        open={snackbarOpen}
        message="Password reset failed."
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbarOpen(false)}
          >
            OK
          </Button>
        }
      />
    </Layout>
  );
};
