import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { DashboardPage } from "../pages/DashboardPage";
import { PageNotFoundPage } from "../pages/PageNotFoundPage";
import { PasswordResetPage } from "../pages/PasswordResetPage";
import { PasswordResetSentPage } from "../pages/PasswordResetSentPage";
import { PasswordUpdatePage } from "../pages/PasswordUpdatePage";
import { SignInPage } from "../pages/SignInPage";
import { RequireAuth } from "./RequireAuth";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard"/>} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route
          path="/password-reset-sent"
          element={<PasswordResetSentPage />}
        />
        <Route path="/password-update" element={<PasswordUpdatePage />} />
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </Router>
  );
};
